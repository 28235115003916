// clown_sound.js
import React, { useState, useEffect, useRef } from 'react';

const BackgroundMusic = React.forwardRef((props, ref) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  // Play sound function to be used externally
  const playSound = () => {
    if (audioRef.current) {
      audioRef.current.volume = 0.2;
      audioRef.current.play();
    }
  };

  // Pass playSound function to the ref so it can be called from outside
  React.useImperativeHandle(ref, () => ({
    playSound
  }));

  useEffect(() => {
    if (audioRef.current && isPlaying) {
      audioRef.current.volume = 0.2;
      audioRef.current.loop = true;
      audioRef.current.play();
    }
  }, [isPlaying]);

  const handlePlayClick = () => {
    if (audioRef.current) {
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  return (
    <div className="background-music">
      <audio ref={audioRef}>
        <source src="/assets/circus.mp3" type="audio/mpeg" />
      </audio>
    </div>
  );
});

export default BackgroundMusic;
