import React from 'react';

function InfoSection() {
  const contractAddress = "DTZ8utxJhFjeQGai3tAdvTLx6efikyWiygqVq6cTun57";
  const solanaExplorerUrl = `https://explorer.solana.com/address/${contractAddress}`;
  const twitterUrl = "https://twitter.com/clown_on_solana"; 
  const dex =`https://raydium.io/swap/?inputMint=${contractAddress}&outputMint=sol`;


  return (
    <section className="info-section">
      <h3>STOP CLOWNING START $CLOWNING!!!</h3>
      
      <p>
        <strong>Contract Address:</strong>{' '}
        <a 
          href={solanaExplorerUrl} 
          target="_blank" 
          rel="noopener noreferrer"
          className="contract-address-link"
        >
          {contractAddress}
        </a>
      </p>

      <p>Get your hands on $CLOWN now.</p>
      
      <a href= {dex} className="buy-button">GET $CLOWN NOW!</a>

      {/* Follow Us Section with Twitter Icon Only */}
      <p className="follow-us">
        Follow us on
        <a 
          href={twitterUrl} 
          target="_blank" 
          rel="noopener noreferrer" 
          className="twitter-link"
        >
          <img 
            src="/assets/x.png" 
            alt="Twitter Icon" 
            className="twitter-icon"
          />
        </a>
      </p>

      {/* New Section: SEND YOUR FRIENDS SOME CLOWN/SOL */}
      <div className="send-section">
        <h3>$CLOWN YOUR FRIENDS</h3>
        <p>SEND THEM $CLOWN.</p>

        {/* Image of the Clown Phantom at the bottom */}
        <img 
          src="/assets/clown_phantom.png" 
          alt="Clown Phantom" 
          className="clown-image"
        />
        <a href={dex} className="buy-button">GET $CLOWN NOW!</a>
      </div>
      
    </section>
  );
}

export default InfoSection;
