import React from 'react';

function Footer() {
  return (
    <footer className="footer">
      <p>Don't miss out, $CLOWN ON SOL.</p>
      <p>GET NOW, CLOWN LATER.</p>
      <p>GET NOW, CLOWN LATER.</p>
      <p>GET NOW, CLOWN LATER.</p>
      <p>GET NOW, CLOWN LATER.</p>
      <p>GET NOW, CLOWN LATER.</p>
      <p>GET NOW, CLOWN LATER.</p>
      <p>GET NOW, CLOWN LATER.</p>
    </footer>
  );
}

export default Footer;
